
#intro {
    height: calc(85vh - 8rem);
    width: 100vw;
    max-width: 75rem;
    margin: 0 auto;
    overflow-y: hidden;
    position: relative;
}

.bg{
    /*position: absolute;*/
    z-index: -1;
    top: -4rem;
    left: -10rem;
    object-fit: cover;
    height: auto;
    width: 55vw;
    /*scale: 0.7;*/
}

.btn{
    border: none;
    border-radius: 2rem; /* Adjust the radius for rounded corners */
    background-color: #ff8000; /* Sets the background color to orange */
    color: white; /* Sets the text color to white */
    padding: 10px 20px; /* Adjust padding as needed */
    font-size: 16px; /* Adjust font size as needed */
    margin: 1rem 0 ;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    width: 15rem;
}

.introContent {
    height: 70vh;
    width: 80vw;
    padding: 2rem;
    font-size: 3rem;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
}

.naam {
    /*margin-top: rem;*/
    font-size: 1.75rem;
    font-weight: 200;
}
.heftruckText {
    color: #ffffff;
}

.introPara {
    font-size: medium;
    font-weight: 300;
}

@media screen and (max-width: 840px){
    .bg{
        height: auto;
        /*width: 50vw;*/
        top: 4rem;
        left: 1rem;
    }
    #into {
        height: auto;

    }
}

@media screen and (max-width: 430px){
    .bg{
        right: -18vw;
    }
    .introContent{
        font-size: 7vw;
    }
    .naam{
        font-size: 7vw;
    }
}