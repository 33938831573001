.navbar{
    height: 5rem;
    width: 100vw;
    max-width: 75rem;
    margin: 0 auto;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: rgba(0, 193, 255, 0.98);
}
.logo{
    object-fit: cover;
    height: 3rem;
    width: 4rem;

}
.logo-image{
    width: 100px;
    height: auto;
    transform: scaleX(-1);

}

.desktopMenuListItem{
    margin: 1rem;
    cursor: pointer;
}
.desktopMenuListItem:hover{
    color: #ffffff;
    padding-bottom: 0.5rem;
    border-bottom: 3px solid #ff8000;

}


.desktopMenuBtn{
    border: none;
    display: inline-block;
    /*float: left;*/
    /*overflow: auto;*/
    border-radius: 20px; /* Adjust the radius for rounded corners */
    background-color: #ff8000; /* Sets the background color to orange */
    color: white; /* Sets the text color to white */
    padding: 10px 20px; /* Adjust padding as needed */
    font-size: 14px; /* Adjust font size as needed */
}

.mobMenu {
    display: none;
    object-fit: cover;
    height: 1.8rem;

}

.navMenu {
    position: absolute;
    top: 4rem;
    right: 0.5rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    height: fit-content;
    min-width: 8rem;
    border-radius: 1rem;
    background-color: #ff8000;
}

.listItem {
    color: white;
    padding: 0.5rem 3rem;
    margin: 0.25rem;
    text-align: center;
}

@media screen and (max-width: 700px) {
    .mobMenu {
        display: flex;
    }
    .desktopMenu {
        display: none;
    }
    .desktopMenuBtn {
        display: none;
    }
}