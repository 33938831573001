.footer {
    overflow: hidden;
    width: 100vw;
    max-width: 65rem;
    display: flex;
    /*align-items: center;*/
    /*justify-content: center;*/
    text-align: center;
    margin: 0 auto;
    height: 13rem;
    /*flex-direction: column;*/

}

#images {
    overflow: hidden;
    width: 50vw;
    max-width: 75rem;
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    justify-content: center;
    text-align: center;
    margin: 0 auto;
}

.slider-slide {
    position: relative;
    display: block;
    padding-bottom: 1rem;
    box-sizing: border-box;
    width: 100%;
    min-height: 0;
    min-width: 0;
    object-fit: contain;
    scale: 80%;
}

.slick-slide {
    object-fit: contain;
    display: block;
    box-sizing: border-box;
    max-width: 300vw; /* Limiting max width of each image */
    margin: 0 auto; /* Centering each image horizontally */
}

.slider-slide img {
    width: 50%;
    height: auto;
    justify-content: center;
    object-fit: contain;

}

@media screen and (max-width: 430px) {
    .slick-slider {
        width: 100%;
    }
}