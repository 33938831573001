#contact {
    overflow: hidden;
    width: 100vw;
    max-width: 65rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
}

.aboutUs {
    font-size: 3rem;
    font-weight: 600;
    padding-top: 3rem;
    margin-bottom: 1rem;
}

.omschrijving {
    font-weight: 400;
    font-size: 1rem;
    max-width: 50rem;
    padding: 0 2rem;

}
.activiteiten{
    margin: 1rem;
    width: 100vw;
    max-width: 80%;
    text-align: left;
}

.activiteitText {
    display: flex;
    margin: 1rem;
    padding: 1.5rem 2rem;
    border-radius: 0.5rem;
    background: #ff8000;

}

.activiteitLogo {
    object-fit: cover;
    height: 3rem;
    width: 3rem;
    margin-right: 2rem;
}

.activiteitLogo2 {
    object-fit: cover;
    height: 3rem;
    width: 3rem;
    margin-right: 2rem;
    transform: scaleX(-1);
}
.activiteitText > p {
    font-size: 1rem;
    font-weight: 200;
    margin-top: 2rem;
    margin-left: -5rem;
}

.activiteitText > h2 {
    display: block;
    margin-bottom: 1rem;
}

@media screen and (max-width: 720px){
    .omschrijving>p{
        font-size: 2vw;
    }
}

@media screen and (max-width: 480px){
    .omschrijving>p{
        font-size: 3vw;
    }
}