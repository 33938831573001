#images {
    overflow: hidden;
    width: 80vw;
    max-width: 75rem;
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    justify-content: center;
    text-align: center;
    margin: 0 auto;
}

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    width: 100%;
}

.slick-slide {
    /*display: block;*/
    /*box-sizing: border-box;*/
    max-width: 300vw; /* Limiting max width of each image */
    margin: 0 auto; /* Centering each image horizontally */
}

.slick-slide img {
    width: 80%;
    height: auto;
    justify-content: center;

}

@media screen and (max-width: 430px) {
    .slick-slider {
        width: 100%;
    }
}